import React, { useState, useContext, Fragment, useEffect } from 'react';
import { AppContext } from 'AppContext';
import moment from 'moment';
import TimeField from 'react-simple-timefield';

import { Button, Grid, Paper, Checkbox } from '@mui/material';
import { Mic } from '@mui/icons-material';

import AITranscriptFiles from 'components/AITranscriptFiles';
import AssigneeSelect from 'components/AssigneeSelect';
import Heading from 'components/Heading';
import Select from 'components/Select';
import TextField from 'components/TextField';
import SplitsList from 'components/SplitsList';
import Deadline from 'components/Deadline';
import ClaimExtensionsViewer from 'components/ClaimExtensionsViewer';
import Transcript from 'components/Transcript';
import Feedback from 'components/Feedback';
import Assessment from 'components/Assessment';
import Disclaimer from 'components/Disclaimer';
import DelayReason from 'components/DelayReason';
import LoadingDialog from 'components/LoadingDialog';
import Search from 'components/Search';
import WrongLanguage from 'components/WrongLanguage';
import { Transcription } from 'components/AITranscript';
import WarningBanner from 'components/WarningBanner';

import {
  getInteraction,
  saveInteraction,
  submitInteraction,
  cancelInteractionClaim,
  cancelInteraction,
  downloadTranscript,
  downloadEditedTranscript,
  uploadTranscript,
  uploadEditedTranscript,
  suspendInteraction,
  resumeInteraction,
  updateWrongLanguage,
  savePreferredProofreader
} from 'services/InteractionsService';
import { searchInteractions } from 'services/SearchService';
import { offsetDeadline } from 'services/ClaimsService';
import { getSplits } from 'services/SplitsService';
import { getQAs, getTranscribers, getProofreaders } from 'services/UsersService';

import {
  LANGUAGES,
  INTERACTION_HARD_DEADLINE_OFFSET,
  DEADLINE_EXTENSION_INCREMENT,
  USER_ROLES,
  USER_GROUPS
} from 'Constants';

import {
  utcToLocalDateString,
  hhmmssToSeconds,
  secondsToHhmmss,
  doDownload,
  getFileName,
  userToString,
  isTranscriptExpired,
  isNonEnglish,
  sort,
  determineRoleAssignee
} from 'utils';
import { isAdminOrInHouseQA, isAdmin, isProofreader, isQA, isInHouseQA } from 'utils/roles';
import {
  interactionInProgress,
  interactionClaimed as interactionInQA,
  interactionCompleted as interactionDone,
  interactionInProgressProofreader,
  interactionReadyForProofreader
} from 'utils/interaction';
import { INTERACTION_STATES } from '../../Constants';
import { formatLoadedUsers } from './utils';
import { LABELS } from './constants';
import { SUCCESS_MESSAGE } from './text';

export const testIds = {
  transcriptFeedback: 'transcript-feedback',
  qa: 'qa',
  proofreader: 'proofreader',
  preferredProofreader: 'preferred_proofreader'
};

export const InteractionDetails = (props) => {
  const [interaction, setInteraction] = useState({});
  const [splits, setSplits] = useState([]);
  const [newSplit, setNewSplit] = useState({
    startTimeSecond: 0,
    endTimeSecond: 0
  });
  const [transcribers, setTranscribers] = useState([]);
  const [qAs, setQAs] = useState([]);
  const [proofreaders, setProofreaders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [acceptedSecondReview, setAcceptedSecondReview] = useState(false);
  const [temporaryTranscriptFile, setTemporaryTranscriptFile] = useState(undefined);
  const [variant, setVariant] = useState({});

  const appContext = useContext(AppContext);
  const { auth } = appContext;

  useEffect(() => {
    setAcceptedSecondReview(Boolean(interaction.isInaudible));
  }, [interaction.isInaudible]);

  const handleChange = ({ target: { name, value } }) => {
    setInteraction({
      ...interaction,
      [name]: value
    });
  };

  const handleAudioLengthChange = (value) => {
    setInteraction({
      ...interaction,
      audioSeconds: hhmmssToSeconds(value)
    });
  };

  const splitsCompleted = () => {
    if (interaction.fullFile) {
      return true;
    }

    // Check if any split is missing necessary properties
    return splits.every((split) => split.assessment?.id && split.editedTranscript?.id);
  };

  const admin = isAdmin(auth.role);
  const adminOrInHouseQA = isAdminOrInHouseQA(auth.role);
  const interactionClaimed = interactionInQA(interaction.state);
  const interactionCompleted = interactionDone(interaction.state);
  const interactionIsReadyForProofreader = interactionReadyForProofreader(interaction.state);

  const feedbackExists = interaction.feedback?.id;
  const transcriptFeedbackExists = interaction.transcriptFeedback?.id;
  const transcriptHasExpired = isTranscriptExpired(interaction.transcript?.transcriptDate);
  const transcriptExists = interaction.transcript?.id;

  const isProofreaderClaim =
    !isQA(auth.role) && interactionInProgressProofreader(interaction.state);
  const readyToDetermineProofreadingAssignee = Boolean(
    interactionIsReadyForProofreader || interaction.proofreadingClaim
  );

  // Display checks
  const displayWrongLanguage =
    !(isQA(auth.role) && auth.userContext.groupName !== USER_GROUPS.AiHdGpt) &&
    !isProofreader(auth.role);
  const displayAssignee = interaction.id && !isNonEnglish(interaction);
  const displayQAAssigneeSelect = qAs.length > 0 && displayAssignee;

  // Disabled checks
  const feedbackDisabled =
    !splitsCompleted() ||
    (interactionCompleted && !adminOrInHouseQA) ||
    (interaction.aiHdGpt && !interaction.isGoodQualityGpt);
  const disclaimerDisabled =
    (interaction.aiHdGpt && (!interaction.isGoodQualityGpt || !transcriptFeedbackExists)) ||
    !feedbackExists ||
    (!interaction.qaOnly && !transcriptExists) ||
    (isProofreader(auth.role) && !interaction.isProofreadingTranscriptUploaded);
  const transcriptDisabled =
    !feedbackExists || (interaction.aiHdGpt && !transcriptFeedbackExists) || transcriptHasExpired;
  const qaAssigneeDisabled = interaction.claim != null || interaction.fullFile;

  const proofreaderFlowDisabled =
    interaction.fullFile || !proofreaders.length || !interaction.aiHdGpt;
  const proofreaderAssigneeDisabled = !interactionIsReadyForProofreader || proofreaderFlowDisabled;
  const preferredProofreaderAssigneeDisabled =
    !isAdmin(auth.role) ||
    interactionInProgressProofreader(interaction.state) ||
    proofreaderFlowDisabled;

  const claimData = isProofreaderClaim
    ? {
        claim: interaction.proofreadingClaim,
        claimKey: 'proofreadingClaim'
      }
    : {
        claim: interaction.claim,
        claimKey: 'claim'
      };

  const { claim, claimKey } = claimData;

  const showDeadline = () => {
    return variant.showDeadline && (adminOrInHouseQA || !interactionClaimed);
  };

  const isDeadlineExtendable = () => {
    return claim && claim.claimer.id === auth.id;
  };

  const showDelayReasons =
    claim &&
    (claim.delayReason || (moment(claim.finalEyeDeadline) < moment() && !claim.submissionDate));

  const LOAD_USER_INFO = {
    [USER_ROLES.qa]: {
      fetchData: getQAs,
      setData: setQAs
    },
    [USER_ROLES.transcriber]: {
      fetchData: getTranscribers,
      setData: setTranscribers
    },
    [USER_ROLES.proofreader]: {
      fetchData: getProofreaders,
      setData: setProofreaders
    }
  };

  useEffect(() => {
    const loadUsers = async (role) => {
      const { fetchData, setData } = LOAD_USER_INFO[role];
      try {
        // To do: implement search so we dont need to load all users up front
        let { data } = await fetchData();
        const formattedUsers = formatLoadedUsers(
          data,
          interaction.sourceLanguage,
          interaction.targetLanguage
        );
        setData(formattedUsers);
      } catch (error) {
        appContext.showNotification('error', error.message);
      }
    };

    if (interaction.id) {
      if (admin) loadUsers(USER_ROLES.transcriber);

      if (adminOrInHouseQA) {
        loadUsers(USER_ROLES.qa);

        if (interaction.aiHdGpt) loadUsers(USER_ROLES.proofreader);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interaction.id]);

  const handleDeadlineChange = async (extend) => {
    try {
      setLoading(true);
      const result = await offsetDeadline(
        claim.id,
        extend ? DEADLINE_EXTENSION_INCREMENT : -DEADLINE_EXTENSION_INCREMENT
      );
      setInteraction({ ...interaction, claim: result.saved });
      appContext.showNotification('success', SUCCESS_MESSAGE.deadlineModified);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSplitChange = (split) => {
    const updateSplits = Array.from(splits);
    updateSplits[splits.findIndex((s) => s.id === split.id)] = split;
    setSplits(updateSplits);
  };

  const handleNewSplitChange = (split) => setNewSplit(split);

  const handleInteractionSave = async (successMessage) => {
    let leaving = false;
    try {
      const isNewInteractionView = variant.heading === variants.new.heading;
      setLoading(true);
      const newInteraction = {
        ...interaction,
        ...(!isNewInteractionView && { isInaudible: acceptedSecondReview })
      };

      const response = await saveInteraction(newInteraction);
      if (isNewInteractionView) {
        leaving = true;
        props.history.push(`/interactions/${response.saved.savedInteraction.id}`);
      }
      appContext.showNotification('success', successMessage);
      setInteraction(response.saved);
      if (response.saved.fullFile) {
        const loadedSplits = await getSplits(response.saved.id);
        setSplits(sort(loadedSplits.data, 'startTimeSecond'));
      }
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleInteractionCancelClaim = async () => {
    appContext.showDialog(
      'Cancel Interaction QA?',
      'Cancelling will delete all QA work done on this interaction and return it to the queue.',
      () => doInteractionCancelClaim(),
      "Don't Cancel",
      'Cancel Interaction QA'
    );
  };

  const handleSuspend = async () => {
    const action = interaction.suspended ? resumeInteraction : suspendInteraction;
    try {
      setLoading(true);
      const result = await action(interaction.id);
      appContext.showNotification(
        'success',
        `Interaction ${result.saved.suspended ? 'suspended' : 'resumed'} successfully.`
      );
      setInteraction(result.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const doInteractionCancelClaim = async () => {
    try {
      setLoading(true);
      const result = await cancelInteractionClaim(interaction.id);
      appContext.showNotification('success', SUCCESS_MESSAGE.interactionCancelled);
      setInteraction(result);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInteractionCancel = () => {
    appContext.showDialog(
      'Cancel Interaction?',
      `Cancelling this interaction deletes it's splits and all work done on this interaction.  Do you wish to continue?`,
      doCancel,
      "Don't Cancel",
      'Cancel Interaction'
    );
  };

  const handleAdditionalSplit = (split) => {
    setSplits(sort(splits.concat(split), 'startTimeSecond'));
  };

  const handleDeletedSplit = (split) => {
    setSplits(splits.concat(split).filter((s) => s.id !== split.id));
  };

  const doCancel = async () => {
    let leaving = false;
    try {
      setLoading(true);
      await cancelInteraction(interaction.id);
      leaving = true;
      props.history.push('/interactions');
      appContext.showNotification(
        'success',
        SUCCESS_MESSAGE.interactionIdCancelled(interaction.id)
      );
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleDownloadTranscript = async (edited) => {
    const download = edited ? downloadEditedTranscript : downloadTranscript;
    const path = edited
      ? interaction.editedTranscript.s3FilePath
      : interaction.transcript.s3FilePath;
    const file = await download(interaction.id);
    doDownload(getFileName(path), file);
  };

  const handleSubmitTranscriptUpload = async (file) => {
    const newFile = temporaryTranscriptFile || file;
    try {
      setLoading(true);
      const { saved } = await uploadTranscript(interaction.id, newFile);
      setInteraction(saved);
      setTemporaryTranscriptFile(undefined);
      appContext.showNotification('success', SUCCESS_MESSAGE.transcriptSubmitted);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTranscriptUpload = async (file, edited) => {
    try {
      if (edited) {
        setLoading(true);
        const result = await uploadEditedTranscript(interaction.id, file);
        setInteraction(result.saved);
      } else {
        if ([USER_ROLES.admin, USER_ROLES.inHouseQa, USER_ROLES.proofreader].includes(auth.role)) {
          setTemporaryTranscriptFile(file);
        } else {
          handleSubmitTranscriptUpload(file);
        }
      }
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackChange = ({ target: { name, value } }) => {
    const feedback = { ...interaction.feedback, [name]: value };
    setInteraction({
      ...interaction,
      feedback
    });
  };

  const handleTranscriptFeedbackChange = ({ target: { name, value } }) => {
    const transcriptFeedback = {
      ...interaction.transcriptFeedback,
      [name]: value
    };

    setInteraction({
      ...interaction,
      transcriptFeedback
    });
  };

  const handleAssessmentChange = ({ target: { name, value } }) => {
    const assessment = { ...interaction.assessment, [name]: value };
    if (name === 'totalGrade') {
      /// As of Apr 2022, Interactions have only one grade (total grade) in their assessment rather than
      /// grades across 7 categories.  To avoid changing the schema/structure (in case we wish to
      /// re-introduce these categories to Interaction assessments), assign the incoming totalGrade to the
      /// underlying categories.
      assessment.speakerIdentification = value;
      assessment.styleGuideAdherence = value;
      assessment.sentenceStructure = value;
      assessment.punctuation = value;
      assessment.wordErrorRate = value;
      assessment.consistency = value;
      assessment.completion = value;
      assessment.totalGrade = value;
    }
    setInteraction({
      ...interaction,
      assessment
    });
  };

  const handleClaimChange = ({ target: { name, value } }) => {
    setInteraction({
      ...interaction,
      [claimKey]: { ...claim, [name]: value }
    });
  };

  const handleDisclaimerAccepted = async () => {
    let leaving = false;
    try {
      setLoading(true);
      await submitInteraction(interaction, claim.delayReason);
      leaving = true;
      appContext.showNotification('success', SUCCESS_MESSAGE.qaComplete);
      props.history.push('/interactions');
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      if (!leaving) setLoading(false);
    }
  };

  const handleSubmitWrongLanguage = async (body) => {
    setLoading(true);
    try {
      const response = await updateWrongLanguage(interaction.id, body);
      setInteraction(response.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const changeAssignee = async (_event, { id }, role) => {
    try {
      setLoading(true);
      const response = await saveInteraction({ ...interaction, assignee: { id } });
      appContext.showNotification('success', SUCCESS_MESSAGE.roleAssigned(role));
      setInteraction(response.saved);
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const changePreferredProofreader = async (_event, value) => {
    try {
      setLoading(true);
      await savePreferredProofreader(interaction.id, value);
      appContext.showNotification(
        'success',
        SUCCESS_MESSAGE.roleAssigned(LABELS.asssigneeSelect.preferredProofreader)
      );
      setInteraction({
        ...interaction,
        preferredProofreader: value
      });
    } catch (error) {
      appContext.showNotification('error', error.message);
    } finally {
      setLoading(false);
    }
  };

  const variants = {
    new: {
      heading: 'New Interaction',
      showDeadline: false,
      showAdminActions: false,
      readOnly: false
    },
    details: {
      heading: 'Interaction Details',
      showDeadline: true,
      showAdminActions: admin,
      readOnly: true
    }
  };

  if (!variant.heading) {
    setVariant(variants[props.variant]);
  } else if (variants[props.variant].heading !== variant.heading) {
    setVariant(variants[props.variant]);
    setInteraction({ id: null }); // Reset interaction
  }

  const getInteractionId = () => {
    // If in details view and invoiceNumber is present, return ID and invoiceNumber.
    if (variant.readOnly && interaction.invoiceNumber) {
      return `${interaction.alphasightsId} (${interaction.invoiceNumber})`;
    }
    // Otherwise, return just the alphasightsId or an empty string if it's undefined.
    return interaction.alphasightsId || '';
  };

  useEffect(() => {
    const loadInteraction = async (id) => {
      let leaving = false;
      try {
        setLoading(true);
        const loadedInteraction = await getInteraction(id);
        if (!loadedInteraction) {
          throw Error(`Could not find interaction with id ${id}`);
        }
        setInteraction(loadedInteraction);
        const loadedSplits = await getSplits(id);
        setSplits(sort(loadedSplits.data, 'startTimeSecond'));
        setNewSplit({
          ...newSplit,
          interaction: loadedInteraction,
          endTimeSecond: loadedInteraction.audioSeconds
        });
      } catch (error) {
        appContext.showNotification('error', error.message);
        leaving = true;
        props.history.push('/interactions');
      } finally {
        if (!leaving) setLoading(false);
      }
    };

    if (props.match.params.id) loadInteraction(props.match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  return (
    <Fragment>
      <LoadingDialog show={loading} />
      {adminOrInHouseQA && variant.heading !== variants.new.heading && (
        <Search
          searchFunc={searchInteractions}
          onSelect={(id) => props.history.push(`/interactions/${id}`)}
        />
      )}
      <div className='p-8'>
        <WarningBanner />
        <Paper className='pb-8'>
          <Heading title={variant.heading} className='p-5' />
          {interaction.state === INTERACTION_STATES.cancelled && (
            <div className='mb-5 state-color-cancel-secondary text-2xl font-bold'>
              CANCELLED ON {utcToLocalDateString(interaction.cancelDate)}
            </div>
          )}
          {(interaction.suspended ||
            interaction.state === INTERACTION_STATES.needsReviewIsWrongLanguage.value) && (
            <div className='mb-5 state-color-actionable text-2xl font-bold'>
              {interaction.suspended
                ? 'SUSPENDED'
                : INTERACTION_STATES.needsReviewIsWrongLanguage.label}
            </div>
          )}
          {interaction.qaOnly && adminOrInHouseQA && (
            <div className='mb-5 state-color-complete text-2xl font-bold'>QA ONLY</div>
          )}
          <Grid container direction='column'>
            <Grid item>
              {interactionClaimed && (
                <div className='flex justify-center'>
                  <div className='ml-5 w-full flex flex-row items-baseline'>
                    <div className='w-1/2 flex justify-center'>
                      <Deadline
                        claim={claim}
                        hardDeadlineOffset={INTERACTION_HARD_DEADLINE_OFFSET}
                        onChangeClick={adminOrInHouseQA ? handleDeadlineChange : null}
                        showDetails={true}
                        extendable={isDeadlineExtendable()}
                        extensionsViewable
                      />
                    </div>
                    {admin && (
                      <div className='flex flex-row items-center'>
                        <div className='text-xs mr-4'>
                          <span>Claimed by:</span>
                          <span className='ml-1 font-bold'>{userToString(claim.claimer)}</span>
                        </div>
                        <Button variant='outlined' onClick={handleInteractionCancelClaim}>
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item>
              <div className='grid-row flex justify-center items-end'>
                {props.variant === 'new' ? (
                  <TextField
                    name='audioUrl'
                    label='Audio URL'
                    value={interaction.audioUrl}
                    className='input-1-3'
                    onChange={handleChange}
                    inputProps={{ readOnly: variant.readOnly }}
                  />
                ) : (
                  <div className='input-1-3'>
                    <Button
                      variant='outlined'
                      href={`/api/interactions/audio/${interaction.id}`}
                      startIcon={<Mic />}
                      size='large'
                      disabled={!adminOrInHouseQA && !interactionClaimed}
                      download>
                      Audio file
                    </Button>
                  </div>
                )}
                <div className='w-1/3'>
                  {interactionCompleted && claim?.extensions?.length > 0 && (
                    <div className='w-1/2 flex justify-start pl-6'>
                      <ClaimExtensionsViewer claimExtensions={claim.extensions} />
                    </div>
                  )}
                </div>
              </div>
              <div className='w-1/3' />
            </Grid>
            <Grid item>
              <div className='grid-row flex justify-center'>
                <TextField
                  name='alphasightsId'
                  label='Interaction ID'
                  value={getInteractionId()}
                  className='input-1-3'
                  onChange={handleChange}
                  inputProps={{ readOnly: variant.readOnly }}
                />
                <TimeField
                  name='audioSeconds'
                  value={secondsToHhmmss(interaction.audioSeconds)}
                  onChange={handleAudioLengthChange}
                  input={<TextField name='audioSeconds' label='Audio Duration' />}
                  showSeconds={true}
                  className='input-1-3'
                  inputProps={{ readOnly: variant.readOnly }}
                />
              </div>
            </Grid>
            {showDeadline() && (
              <Grid item>
                <div className='grid-row flex justify-center'>
                  <TextField
                    name='deadline'
                    label='Interaction Added On'
                    value={utcToLocalDateString(interaction.transcriptRequestDate)}
                    className='input-1-3'
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    name='deadline'
                    label='Interaction Deadline'
                    value={utcToLocalDateString(interaction.deadline)}
                    className='input-1-3'
                    inputProps={{ readOnly: true }}
                  />
                </div>
              </Grid>
            )}
            <Grid item>
              <div className='grid-row flex justify-center'>
                <TextField
                  name='projectId'
                  label='Project ID'
                  value={interaction.projectId}
                  className='input-1-3'
                  onChange={handleChange}
                  inputProps={{ readOnly: variant.readOnly }}
                />
                <TextField
                  name='projectTopic'
                  label='Project Topic'
                  value={interaction.projectTopic}
                  className='input-1-3'
                  onChange={handleChange}
                  inputProps={{ readOnly: variant.readOnly }}
                />
              </div>
            </Grid>
            <Grid item>
              <div className='grid-row flex justify-center'>
                <Select
                  name='sourceLanguage'
                  label='Source Language'
                  value={interaction.sourceLanguage}
                  values={LANGUAGES}
                  className='input-1-3'
                  onChange={handleChange}
                />
                <Select
                  name='targetLanguage'
                  label='Target Language'
                  value={interaction.targetLanguage}
                  values={LANGUAGES}
                  className='input-1-3'
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='grid-row flex justify-center mt-4'>
                <TextField
                  name='notes'
                  label='Details'
                  value={interaction.notes}
                  multiline
                  variant='outlined'
                  className='input-2-3'
                  onChange={handleChange}
                  inputProps={{ readOnly: variant.readOnly }}
                />
              </div>
            </Grid>
            {(admin || (isInHouseQA(auth.role) && interactionCompleted)) && (
              <Fragment>
                <Grid item xs={12}>
                  <div className='grid-row flex justify-center items-end'>
                    {displayQAAssigneeSelect && (
                      <Fragment>
                        <AssigneeSelect
                          disabled={qaAssigneeDisabled}
                          className='input input-1-3'
                          options={qAs}
                          defaultValue={determineRoleAssignee(
                            interaction.claim,
                            interaction.assignee
                          )}
                          label={LABELS.asssigneeSelect.qa}
                          onChange={(e, value) =>
                            changeAssignee(e, value, LABELS.asssigneeSelect.qa)
                          }
                          dataTestId={testIds.qa}
                        />

                        <div className='input-1-3'>
                          <Checkbox
                            checked={interaction.fullFile}
                            onChange={(event, value) =>
                              handleChange({
                                target: { name: 'fullFile', value }
                              })
                            }
                            value={interaction.fullFile}
                            color='primary'
                            disabled={
                              ![INTERACTION_STATES.new, INTERACTION_STATES.suspended].includes(
                                interaction.state
                              )
                            }
                          />
                          <span
                            className={`text-gray-${
                              interaction.fullFile ? '8' : '5'
                            }00 text-xs font-bold`}>
                            Full File
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='grid-row flex justify-center mt-4'>
                    {displayAssignee && (
                      <AssigneeSelect
                        disabled={proofreaderAssigneeDisabled}
                        className='input-1-3 px-8'
                        options={proofreaders}
                        defaultValue={
                          readyToDetermineProofreadingAssignee
                            ? determineRoleAssignee(
                                interaction.proofreadingClaim,
                                interaction.assignee
                              )
                            : null
                        }
                        label={LABELS.asssigneeSelect.proofreader}
                        onChange={(e, value) =>
                          changeAssignee(e, value, LABELS.asssigneeSelect.proofreader)
                        }
                        dataTestId={testIds.proofreader}
                      />
                    )}
                    <div className='input-1-3'>
                      <Checkbox
                        checked={interaction.priority || false}
                        onChange={(event, value) =>
                          handleChange({
                            target: { name: 'priority', value }
                          })
                        }
                        value={interaction.priority}
                        color='primary'
                        disabled={!interactionInProgress(interaction.state)}
                      />
                      <span
                        className={`text-gray-${
                          interaction.priority ? '8' : '5'
                        }00 text-xs font-bold`}>
                        Priority
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='grid-row flex justify-center mt-4 mb-8'>
                    {displayAssignee && (
                      <>
                        <Fragment>
                          <AssigneeSelect
                            disabled={preferredProofreaderAssigneeDisabled}
                            className='input-1-3 px-8'
                            options={proofreaders}
                            defaultValue={interaction.preferredProofreader}
                            label={LABELS.asssigneeSelect.preferredProofreader}
                            onChange={(e, value) => changePreferredProofreader(e, value)}
                            dataTestId={testIds.preferredProofreader}
                          />
                        </Fragment>
                        <div className='input-1-3'></div>
                      </>
                    )}
                  </div>
                </Grid>
                {admin && (
                  <Grid item className='flex flex-row justify-center'>
                    {(!variant.readOnly || interactionInProgress(interaction.state)) && (
                      <div>
                        <Button
                          variant='outlined'
                          onClick={() => handleInteractionSave('Interaction saved.')}>
                          Save
                        </Button>
                      </div>
                    )}
                    {variant.showAdminActions && interactionInProgress(interaction.state) && (
                      <Fragment>
                        <div className='px-16'>
                          <Button variant='outlined' onClick={handleSuspend}>
                            {interaction.suspended ? 'Resume' : 'Suspend'}
                          </Button>
                        </div>
                        <div>
                          <Button variant='outlined' onClick={handleInteractionCancel}>
                            Cancel Interaction
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </Grid>
                )}
              </Fragment>
            )}
          </Grid>
          {props.variant === 'details' && interaction.id && displayWrongLanguage && (
            <WrongLanguage
              handleSubmit={handleSubmitWrongLanguage}
              isAdmin={isAdmin(auth.role)}
              isWrongLanguage={interaction.isWrongLanguage}
              loadedReason={interaction.wrongLanguageReason || ''}
            />
          )}
        </Paper>
        {interaction.id && interaction.state !== INTERACTION_STATES.cancelled && (
          <Fragment>
            {interaction.aiHdGpt && <AITranscriptFiles interactionId={interaction.id} />}
            <Paper className='my-8 pb-8'>
              <Heading title='Interaction Splits' className='p-5' />
              <SplitsList
                maxSeconds={interaction.audioSeconds}
                splits={splits}
                handleSplitChange={handleSplitChange}
                newSplit={newSplit}
                handleNewSplitChange={handleNewSplitChange}
                transcribers={transcribers}
                handleAdditionalSplit={handleAdditionalSplit}
                handleDeletedSplit={handleDeletedSplit}
                className='my-4 mx-auto w-4/5'
              />
            </Paper>
            {interaction.aiHdGpt && (
              <>
                <Transcription
                  interactionId={interaction.id}
                  setLoading={setLoading}
                  isGoodQualityGpt={interaction.isGoodQualityGpt}
                  setInteraction={setInteraction}
                  interactionLowQualityReason={interaction.isBadQualityGptReason}
                />
                <Feedback
                  feedback={interaction.transcriptFeedback || {}}
                  heading='Transcript Feedback'
                  handleChange={handleTranscriptFeedbackChange}
                  handleSave={() => handleInteractionSave('Interaction feedback saved.')}
                  disabled={feedbackDisabled}
                  variant='transcript'
                  requireMissingWords={false}
                  dataTestId={testIds.transcriptFeedback}
                />
              </>
            )}
            <Feedback
              isSecondReviewCheckboxVisible={[
                INTERACTION_STATES.inProgressQA.value,
                INTERACTION_STATES.inProgressCorrector.value,
                INTERACTION_STATES.inProgressProofreader.value,
                INTERACTION_STATES.needsReview.value,
                INTERACTION_STATES.needsReviewIsWrongLanguage.value
              ].includes(interaction.state)}
              setAcceptedSecondReview={setAcceptedSecondReview}
              acceptedSecondReview={acceptedSecondReview}
              feedback={interaction.feedback || {}}
              heading='Interaction Audio Feedback'
              handleChange={handleFeedbackChange}
              handleSave={() => handleInteractionSave('Interaction feedback saved.')}
              provideAccents
              disabled={feedbackDisabled}
            />
          </Fragment>
        )}
        {!interaction.qaOnly && (
          <Transcript
            hasSubmitButton
            interaction={interaction}
            temporaryTranscriptFile={temporaryTranscriptFile}
            handleSubmitTranscriptUpload={handleSubmitTranscriptUpload}
            transcript={interaction.transcript}
            heading='Interaction Transcript'
            handleUpload={
              !interactionCompleted || adminOrInHouseQA
                ? (file) => handleTranscriptUpload(file, false)
                : null
            }
            handleDownload={() => handleDownloadTranscript(false)}
            className='my-5 pb-5'
            disabled={transcriptDisabled}
          />
        )}
        {(interaction.editedTranscript ||
          (interactionCompleted && adminOrInHouseQA) ||
          isProofreader(auth.role)) && (
          <Transcript
            interaction={interaction}
            transcript={interaction.editedTranscript}
            heading='Edited Interaction Transcript'
            handleUpload={
              [USER_ROLES.admin, USER_ROLES.inHouseQa, USER_ROLES.proofreader].includes(auth.role)
                ? (file) => handleTranscriptUpload(file, true)
                : null
            }
            handleDownload={() => handleDownloadTranscript(true)}
            disabled={
              interaction.editedTranscript &&
              isTranscriptExpired(interaction.editedTranscript.transcriptDate)
            }
            className='my-5 pb-5'
          />
        )}
        {showDelayReasons && (
          <DelayReason
            delayReason={claim.delayReason}
            onDelayReasonChange={handleClaimChange}
            className='my-5 pb-5'
          />
        )}
        {interactionClaimed && (
          <Disclaimer
            proofreadingTranscript={interaction.proofreadingTranscript}
            isInaudible={interaction.isInaudible}
            onAccepted={handleDisclaimerAccepted}
            className='my-5 pb-5'
            disabled={disclaimerDisabled}
          />
        )}
        {interactionCompleted && (adminOrInHouseQA || auth.id === claim.claimer.id) && (
          <Assessment
            assessment={interaction.assessment || {}}
            heading='Interaction Grades'
            handleChange={adminOrInHouseQA ? handleAssessmentChange : null}
            handleSave={
              adminOrInHouseQA ? () => handleInteractionSave('Interaction assessment saved.') : null
            }
            className='my-5'
            hasAdditionalComments
          />
        )}
      </div>
    </Fragment>
  );
};

export default InteractionDetails;
